<script lang="ts" setup>
const { t } = useT();
const { isDesktop } = useDevice();
const { open } = useAppModals();
const { data: appInitData } = useAppInitData();
const isGuest = useIsGuest();
const { handleSupportButtonClick } = useSupportChat();
const loginGuard = useLoginGuard();
const { tournamentData } = useRace();
const { entriesActiveTournament } = useTournamentNotification();
const { isActiveStatus, isFullStatus } = usePiggy({ open });
const { isSweepStakes } = useSwitchMode();
const { isCanPlay } = useBingoData({ immediate: false });
const asideRef = ref();

const props = withDefaults(
	defineProps<{
		show: boolean;
	}>(),
	{ show: false }
);

const emit = defineEmits<{ (event: "toggleSidebar"): void }>();

const { handleLogout } = useProfileData();
const { counterHomePromo } = usePromotionsData();

const toggleShow = () => {
	emit("toggleSidebar");
};

onClickOutside(asideRef, () => {
	if (!props.show) {
		return;
	}
	emit("toggleSidebar");
});

const affiliatePartnerState = ref(false);

const isShowMoneyBox = computed(() => appInitData.value?.moneyBox?.isActive && (isActiveStatus.value || isGuest.value));

const isShowBingo = computed(() => !isGuest.value && !!appInitData.value?.bingo?.isActive);

const hasDeposits = computed(() => appInitData.value?.hasDeposits);

const isShowPartnerItem = computed(() => appInitData.value?.isAffiliate && !isGuest.value);

const isShowRedeem = computed(() => {
	if (appInitData.value?.redeem?.show === false) {
		return false;
	}

	if (isSweepStakes.value) {
		return appInitData.value?.redeem?.isEntries;
	}

	return appInitData.value?.redeem?.isCoins;
});

const handleBingoClick = () => {
	loginGuard({
		success: () => {
			if (isCanPlay.value) {
				open("LazyOModalBingoPlay");
				return;
			}
			open("LazyOModalRacesGames");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
	emit("toggleSidebar");
};

const handleRedeemClick = () => {
	if (!hasDeposits.value) {
		open("LazyOModalRedeemHowItWork");

		return;
	}
	loginGuard({
		success: () => {
			window?.$store?.gaCash?.deposit?.({
				location: "redeem",
				step: "payments_method"
			});
			window?.$cash?.$router?.push?.("/cash/redeem/");
			emit("toggleSidebar");
		},
		fail: () => {
			open("LazyOModalSignup");
			dispatchGAEvent({
				event: "registration",
				location: "header",
				step: "start"
			});
			emit("toggleSidebar");
		}
	});
};

const navigateIfAuthorized = (event: MouseEvent) =>
	loginGuard({
		fail: () => {
			emit("toggleSidebar");
			open("LazyOModalSignup");
			dispatchGAEvent({
				event: "registration",
				location: "header",
				step: "start"
			});
			event.preventDefault();
			event.stopPropagation();
		}
	});

const handleLinkClick = navigateIfAuthorized;

const handleSupportClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "left_menu",
		button_name: "support"
	});
	handleSupportButtonClick();
	toggleShow();
};

const handleDepositStreakClick = () => {
	emit("toggleSidebar");
	loginGuard({
		success: () => {
			open("LazyOModalDepositStreak");
		},
		fail: () => {
			open("LazyOModalSignup");
			dispatchGAEvent({
				event: "registration",
				location: "header",
				step: "start"
			});
		}
	});
};
</script>
<template>
	<AOverlay v-if="show" :modifiers="['fixed']" :class="{ show }" :hideOverflow="true">
		<Transition :name="isDesktop ? 'slide' : 'slide-mobile'" appear>
			<aside ref="asideRef">
				<AIconButton class="close-btn" variant="ghost" @click="toggleShow">
					<NuxtIcon name="24/close-yellow" filled />
					<AText variant="toledo">
						{{ t("Close") }}
					</AText>
				</AIconButton>

				<div class="links-wrapper">
					<NuxtLink to="/" class="link">
						<AText variant="tanzay" :modifiers="['bold']" class="text-coro label">
							{{ t("Home") }}
						</AText>
					</NuxtLink>
					<NuxtLink to="/issues/all-games/" class="link">
						<AText variant="tanzay" :modifiers="['bold']" class="text-coro label">
							{{ t("Games") }}
						</AText>
					</NuxtLink>
					<AButton
						v-if="!isGuest && !!appInitData?.depositStreak?.isActive"
						class="nav-button text-tlalnepantla"
						@click="handleDepositStreakClick"
					>
						<AText :size="32" :modifiers="['bold']" class="text-coro">
							{{ t("depositStreak.name") }}
						</AText>
					</AButton>
					<NuxtLink
						v-if="tournamentData?.isActiveStatus"
						to="/tournaments/"
						class="link"
						@click.capture="handleLinkClick"
					>
						<AText variant="tanzay" :modifiers="['bold']" class="text-coro label">
							{{ t("Tournaments") }}
						</AText>
						<AText v-if="entriesActiveTournament" variant="tanzay" :modifiers="['bold']" class="text-сirebon">
							1
						</AText>
					</NuxtLink>
					<NuxtLink to="/promotions/" class="link" @click.capture="handleLinkClick">
						<AText variant="tanzay" :modifiers="['bold']" class="text-coro label">
							{{ t("Promotions") }}
						</AText>
						<AText variant="tanzay" :modifiers="['bold']" class="text-сirebon">
							{{ counterHomePromo }}
						</AText>
					</NuxtLink>
					<div v-if="isShowBingo" class="link" @click.capture="handleBingoClick">
						<AText variant="tanzay" :modifiers="['bold', 'capitalize']" class="text-coro label">
							{{ t("sidebar.link.bingo") }}
						</AText>
						<AText v-if="isCanPlay" variant="tanzay" :modifiers="['bold']" class="text-сirebon"> 1 </AText>
					</div>
					<NuxtLink v-if="isShowMoneyBox" to="/piggy-bank/" class="link" @click.capture="handleLinkClick">
						<AText variant="tanzay" :modifiers="['bold', 'capitalize']" class="text-coro label">
							{{ t("Piggy bank") }}
						</AText>
						<AText v-if="isFullStatus" variant="tanzay" :modifiers="['bold']" class="text-сirebon"> 1 </AText>
					</NuxtLink>
					<NuxtLink v-if="!isGuest" to="/game/" class="link">
						<AText variant="tanzay" :modifiers="['bold']" class="text-coro label">
							{{ t("Account") }}
						</AText>
					</NuxtLink>
					<NuxtLink v-if="!isGuest" to="/referral/" class="link">
						<AText variant="tanzay" :modifiers="['bold']" class="text-coro label">
							{{ t("Invite friends") }}
						</AText>
					</NuxtLink>
					<NuxtLink v-if="!isGuest" to="/how-it-works/" class="link">
						<AText variant="tanzay" :modifiers="['bold']" class="text-cannes label">
							{{ t("How it works?") }}
						</AText>
					</NuxtLink>
					<div v-if="isShowRedeem" class="nav-link" @click="handleRedeemClick">
						<AText variant="toledo" :modifiers="['underline']" class="text-сirebon">
							{{ t("Redeem") }}
						</AText>
					</div>
					<div class="nav-link" @click="handleSupportClick">
						<AText variant="toledo" :modifiers="['underline']" class="text-сirebon">
							{{ t("Support") }}
						</AText>
					</div>
					<div v-if="!isGuest" class="nav-link">
						<NuxtLink v-if="!isShowPartnerItem" to="/affiliates/">
							<AText variant="toledo" :modifiers="['underline']" class="text-сirebon">
								{{ t("Affiliate") }}
							</AText>
						</NuxtLink>

						<AText
							v-else
							:class="[{ 'is-disabled': affiliatePartnerState }]"
							variant="toledo"
							:modifiers="['underline']"
							class="text-сirebon partner"
							@click="affiliatePartnerState = !affiliatePartnerState"
						>
							{{ t("Affiliate") }}

							<NuxtIcon
								class="arrow-icon"
								:name="`16/${affiliatePartnerState ? 'arrow-up-small' : 'arrow-down-small'}`"
								filled
							/>
						</AText>
					</div>
					<Transition name="fade">
						<div v-if="affiliatePartnerState">
							<div class="nav-link affiliate">
								<NuxtLink to="/affiliates/partners-account">
									<AText variant="toledo" :modifiers="['underline']" class="text-сirebon">
										{{ t("Partner’s Account") }}
									</AText>
								</NuxtLink>
							</div>
						</div>
					</Transition>
					<div v-if="!isGuest" class="nav-link" @click="handleLogout">
						<AText variant="toledo" :modifiers="['underline']" class="text-сirebon">
							{{ t("Log Out") }}
						</AText>
					</div>
				</div>

				<MSidebarSliderWrapper class="aside-menu-slider" @toggle-asidebar="emit('toggleSidebar')" />
			</aside>
		</Transition>
	</AOverlay>
</template>
<style scoped lang="scss">
.overlay {
	z-index: 100;
	display: none;
	width: 100%;

	&.show {
		display: flex;
		justify-content: flex-end;

		@include media-breakpoint-down(lg) {
			@media (orientation: landscape) {
				justify-content: flex-start;
			}
		}
	}
}
aside {
	background: var(--goiania);
	width: var(--o-sidebar-width);
	max-width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: gutter(3);
	padding: gutter(3) gutter(2);
	overflow: auto;

	@include media-breakpoint-down(sm) {
		width: 100dvw;
	}

	a {
		display: inline-flex;
		text-decoration: none;
	}
}

.aside-menu-slider {
	margin-top: auto;
}

.close-btn {
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
	gap: gutter(1);
	& > * {
		color: var(--сirebon);
	}

	.nuxt-icon {
		font-size: 24px;

		&:deep(svg) {
			margin-bottom: 0;
		}
	}
}

.links-wrapper {
	display: flex;
	flex-direction: column;
	gap: gutter(1.1);
	margin-top: gutter(0.375);
}

.link {
	display: flex;
	align-items: center;
	gap: gutter(1.125);
	position: relative;
	z-index: 1;
	cursor: pointer;
	width: 100%;
	line-height: 19.04px;

	&:hover,
	&.router-link-active {
		.label {
			color: var(--coyoacan);
		}
	}
}

.nav-button {
	padding: 0;
	border: none;
	justify-content: flex-start;
	position: relative;
	overflow: initial;

	&:hover {
		background: transparent;

		span {
			color: var(--coyoacan);
		}
	}

	.lucky-roulette-badge {
		position: absolute;
		top: -10px;
		right: 30px;
	}
}

.nav-link {
	margin-top: 10px;
	&:first-child {
		margin-top: 25px;
	}
	&:hover,
	&.router-link-active {
		* {
			text-decoration: none;
		}
	}
	&.affiliate {
		padding-left: 24px;
		margin-top: 10px;
	}
	.is-disabled {
		color: var(--cherkessk);

		& > * {
			cursor: default;
			text-decoration: none;
			color: var(--cherkessk);
		}

		&:deep(svg) path {
			fill: var(--cherkessk);
		}
	}
	.partner {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}

.nuxt-icon {
	font-size: 20px;

	&:deep(svg) {
		transition: 0.2s ease;
	}
}

.promo-banner {
	margin-top: auto;
}

$transition: all 250ms ease-in-out;
.fade-enter-active,
.fade-leave-active {
	transition: $transition;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.slide-enter-active {
	transition: $transition;
	transform: translateX(100%);
}
.slide-enter-to {
	transform: translateX(0%);
}
.slide-leave-active {
	transition: $transition;
	transform: translateX(0%);
}
.slide-leave-to {
	transform: translateX(100%);
}

.slide-mobile-enter-active {
	transition: $transition;
	transform: translateX(-100%);
}
.slide-mobile-enter-to {
	transform: translateX(0%);
}
.slide-mobile-leave-active {
	transition: $transition;
	transform: translateX(0%);
}
.slide-mobile-leave-to {
	transform: translateX(-100%);
}
</style>
